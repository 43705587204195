import React, {useContext, useState} from 'react'
import UserContext from '../context/user'
import {allEmployees} from '../../employees'
import {Typeahead} from 'react-bootstrap-typeahead'
import Header from '../components/header'
import {SelfReviewForm} from '../components/self-review'
import Section from '../components/section'
import {TypeForm, PeerReviewForm} from "../components/typeform"
import Alert from "react-bootstrap/alert"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import OneIcon from "../images/1.png"
import TwoIcon from "../images/2.png"
import ThreeIcon from "../images/3.png"
import FourIcon from "../images/4.png"


const IndexPage = () => {
  const baseUrl = window.location.protocol+'//'+window.location.host+'/'

  const user = useContext(UserContext)


  if (user.employee.department == 'design')
  { 
    return (
      <Section>
        <Header/>
        <br/>

        <h1 style={{color: "#5e9ca0", fontSize: "30px"}}>Welcome to the Reviews Hub Page, {user.employee.nickname}!</h1>
        <h2 style={{color: "#2e6c80", fontSizse: "24px"}}>From here you can:</h2>
        <br/>
        <ol style={{listStyle: "none", fontSize: "14px", lineHeight: "32px", fontWeight: "bold"}}>
        <li style={{clear: "both"}}>
          <img src={OneIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"self-review"}>Step 1: Self Review</a> (Optional)
          </div>
        </li>
        <br/>
        <li style={{clear: "both"}}>
          <img src={TwoIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"manager-review"}>Step 2: Manager Review</a> (Optional)
          </div>
        </li>
        <br/>
        <li style={{clear: "both"}}>
          <img src={ThreeIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"peer-review"}>Step 3: Peer Reviews</a> 
          </div>
        </li>
        </ol>

        <br/>
        <hr/>
        <div style={{color: "black", fontSize: "14px", fontWeight: "normal"}}>
          We'd like to thank you for taking time out of your day to complete these surveys. We know that you're busy and have other responsibilities on your plate, but providing candid feedback is how the Certain Affinity team continues to learn and improve.
        </div>

      </Section>
    )
  } else if (user.employee.department == 'engineering')
  {
    return (
      <Section>
        <Header/>
        <br/>

        <h1 style={{color: "#5e9ca0", fontSize: "30px"}}>Welcome to the Reviews Hub Page, {user.employee.nickname}!</h1>
        <h2 style={{color: "#2e6c80", fontSizse: "24px"}}>From here you can:</h2>
        <br/>
        <ol style={{listStyle: "none", fontSize: "14px", lineHeight: "32px", fontWeight: "bold"}}>
        <li style={{clear: "both"}}>
          <img src={OneIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"self-review"}>Step 1: Self Review</a>
          </div>
        </li>
        <br/>
        <li style={{clear: "both"}}>
          <img src={TwoIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"manager-review"}>Step 2: Manager Review</a>
          </div>
        </li>
        <br/>
        <li style={{clear: "both"}}>
          <img src={ThreeIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"peer-review"}>Step 3: Peer Reviews</a> (optional, but highly encouraged!) 
          </div>
        </li>
        </ol>

        <br/>
        <hr/>
        <div style={{color: "black", fontSize: "14px", fontWeight: "normal"}}>
          We'd like to thank you for taking time out of your day to complete these surveys. We know that you're busy and have other responsibilities on your plate, but providing candid feedback is how the Certain Affinity team continues to learn and improve.
        </div>

      </Section>
    )
  } else {
    return (
      <Section>
        <Header/>
        <br/>

        <h1 style={{color: "#5e9ca0", fontSize: "30px"}}>Welcome to the Reviews Hub Page, {user.employee.nickname}!</h1>
        <h2 style={{color: "#2e6c80", fontSizse: "24px"}}>From here you can:</h2>
        <br/>
        <ol style={{listStyle: "none", fontSize: "14px", lineHeight: "32px", fontWeight: "bold"}}>
        <li style={{clear: "both"}}>
          <img src={OneIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"self-review"}>Step 1: Self Review</a>
          </div>
        </li>
        <br/>
        <li style={{clear: "both"}}>
          <img src={TwoIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"manager-review"}>Step 2: Manager Review</a>
          </div>
        </li>
        <br/>
        <li style={{clear: "both"}}>
          <img src={ThreeIcon} style={{width: "45px", float: "left", marginRight: "15px"}}/>
          <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>
            <a href={baseUrl+"peer-review"}>Step 3: Peer Reviews</a> (optional, but highly encouraged!)
          </div>
        </li>
        </ol>

        <br/>
        <hr/>
        <div style={{color: "black", fontSize: "14px", fontWeight: "normal"}}>
          We'd like to thank you for taking time out of your day to complete these surveys. We know that you're busy and have other responsibilities on your plate, but providing candid feedback is how the Certain Affinity team continues to learn and improve.
        </div>

      </Section>
    )
  }
}

export default IndexPage
